<template>
  <a-spin :spinning="spinning">
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="发货单号">
              <a-input v-model="searchData.shipCode" allowClear placeholder="请输入发货单号"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="订单号">
              <a-input v-model="searchData.orderCode" allowClear placeholder="请输入订单号"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="销售合同号">
              <a-input v-model="searchData.xsHeTong" allowClear placeholder="请输入销售合同号"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="采购合同号">
              <a-input v-model="searchData.cgHeTong" allowClear placeholder="请输入采购合同号"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="经销商">
              <a-input v-model="searchData.dealerName" allowClear placeholder="请输入经销商名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="制单人">
              <a-input v-model="searchData.createUser" allowClear placeholder="请输入制单人"></a-input>
            </a-form-model-item>
          </a-col>
<!--          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="状态">
              <DictSelect
                field="shipStatus"
                :value.sync="searchData.deliveryStatus"
                style="width: 100%"
                placeholder="请选择状态"
              ></DictSelect>
            </a-form-model-item>
          </a-col>-->
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="收货人">
              <a-input v-model="searchData.consignee" allowClear placeholder="请输入收货人"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="收货电话">
              <a-input v-model="searchData.phone" allowClear placeholder="请输入收货电话"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="收货地址">
              <PCD
                ref="pcd"
                placeholder="收货地址"
                :province.sync="searchData.province"
                :provinceId.sync="searchData.provinceId"
                :city.sync="searchData.city"
                :cityId.sync="searchData.cityId"
                :district.sync="searchData.area"
                :districtId.sync="searchData.areaId"
              ></PCD>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="详细地址">
              <a-input v-model="searchData.address" :max-length="50" allowClear placeholder="请输入详细地址"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="发货日期">
              <DateRange :startTime.sync="searchData.startDeliveryTime" :endTime.sync="searchData.endDeliveryTime"></DateRange>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="创建日期">
              <DateRange :startTime.sync="searchData.startTime" :endTime.sync="searchData.endTime"></DateRange>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button
          type="primary"
          v-if="isDeliver &&
            selectedRows.length > 0 && selectedRows[0].deliveryStatus == 0 && selectedRows[0].shippingOrderStatus == 2
          "
          @click="toHandler('ship')"
        >发货
        </a-button
        >
        <a-button
          type="primary"
          v-if="isAudit && selectedRows.length > 0 && selectedRows[0].shippingOrderStatus == 1"
          @click="toHandler('audit')"
        >审核
        </a-button
        >
        <a-button
          type="primary"
          v-if="isEditLogistics && selectedRows.length > 0 && selectedRows[0].deliveryType == 2 && selectedRows[0].deliveryStatus == 1"
          @click="toHandler('improve_logistics')"
        >完善物流
        </a-button
        >
        <a-button
          type="primary"
          v-if="isCheckLogistics && selectedRows.length > 0 && selectedRows[0].deliveryStatus != 0"
          @click="toHandler('view_logistics')"
        >查看物流
        </a-button
        >
        <a-button v-if="isCheck" type="primary" @click="toHandler('check')">查看</a-button>
        <a-button type="primary" @click="toHandler('editSaintyCode')" v-if="isShuntianShipNum && selectedRows.length > 0 && selectedRows[0].saintyCode == null && selectedRows[0].supplierCode == 'shuntian'">舜天发货单号</a-button>
<!--        <a-button type="primary" @click="toHandler('push_k3')">推送到K3</a-button>-->
        <a-button
          type="default"
          v-if="isCancel && selectedRows.length > 0 "
          @click="toBatchCancel"
        >取消</a-button>

        <downLoad
          method="get"
          api="/api/order/system/orderShipInfo/excelExportOrderShipInfo"
          :params="selectedRows[0]"
          name="排货单.xlsx"
          v-if="isExportShipOrderDetail && selectedRows.length == 1"
        >导出排货单</downLoad
        >
        <downLoad v-if="isExport" method="post" api="/api/order/system/orderShipInfo/excelExportOrderShipList"
                  :params="searchDataObj"
                  @downLoadDone="downLoadDone"
                  @startDownLoad="startDownLoad"
                  name="菱感发货单(待发货).xls">导出发货单
        </downLoad>


<!--        <a-upload-->
<!--          name="file"-->
<!--          :action="uploadUrl"-->
<!--          :headers="headers"-->
<!--          :showUploadList="false"-->
<!--          @change="uploadChange"-->
<!--        >-->
<!--          <a-button icon="upload" type="default">导入</a-button>-->
<!--        </a-upload>-->

<!--        <a-upload-->
<!--          name="file"-->
<!--          :action="updateK3Url"-->
<!--          :headers="headers"-->
<!--          :showUploadList="false"-->
<!--          @change="uploadChange"-->
<!--        >-->
<!--          <a-button icon="upload" type="default">导入K3同步数据</a-button>-->
<!--        </a-upload>-->
      </div>

      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        :scroll="{ x: 1500 }"
        @change="changeTable"
        :customRow="changeTableRow"
      >
        <span slot="flagInvoice" slot-scope="text">
          {{ text == true ? '是' : '否' }}
        </span>
        <span slot="saintyCode" slot-scope="text,row">
          <span v-if="row.saintyCode != null">{{row.saintyCode}}</span>
          <span v-if="row.saintyCode == null">-</span>
        </span>
        <span slot="orderCode" slot-scope="text, row">
           <div v-for="(item, index) in row.orderCode" :key="index">
         <a @click="checkOrderInfo(item)"> {{ item }} </a>
        </div>
          <!-- <a @click="checkOrderInfo(row)">{{ text }}</a> -->
        </span>
        <span slot="shippingOrderStatus" slot-scope="text">
          <a-tag v-if="text == 1" color="#e6a23c">待审核</a-tag>
          <a-tag v-if="text == 2" color="#108ee9">审核通过</a-tag>
          <a-tag v-if="text == 3" color="#67c23a">审核拒绝</a-tag>
        </span>
        <span slot="deliveryStatus" slot-scope="text">
          <a-tag v-if="text == 0" color="#e6a23c">待发货</a-tag>
          <a-tag v-if="text == 1" color="#108ee9">已发货</a-tag>
          <a-tag v-if="text == 2" color="#67c23a">已收货</a-tag>
        </span>
        <span slot="actualPrice" slot-scope="text">
          {{ text | formatMoney }}
        </span>
        <span slot="flagInvoice" slot-scope="text">
          {{ text == true ? '是' : '否' }}
        </span>
        <span slot="address" slot-scope="text, row">
          {{ row.consignee }},{{ row.phone }}<br/>
          {{ row.provinceName }}{{ row.cityName }}{{ row.areaName }}{{ row.address }}
        </span>
      </a-table>

      <!-- 编辑表单 -->
      <OrderShipInfo-edit-modal ref="OrderShipInfoEditModal" @reload="getData"></OrderShipInfo-edit-modal>
      <ImproveLogistics ref="ImproveLogistics" @reload="getData"></ImproveLogistics>
      <CheckLogistics ref="CheckLogistics" @reload="getData"></CheckLogistics>
      <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>

      <a-modal
        title="取消订单"
        width="30%"
        :visible="cancelVisible"
        :confirm-loading="confirmLoading"
        :footer="null"
        :maskClosable="false"
        @cancel="cancelVisible = false"
      >
        <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          <a-textarea v-model="cancelRemark" placeholder="取消原因"></a-textarea>
        </a-form-model>
        <div class="footer-bts">
          <a-button type="default" @click="cancelVisible = false">关闭</a-button>
          <a-button type="primary" :loading="cancelLoading" @click="cancelShipInfo">确定</a-button>
        </div>
      </a-modal>

      <!-- 审核 -->
      <InvoiceReview ref="InvoiceReview" @reload="getData"></InvoiceReview>
    </div>
    <a-modal title="舜天发货单号" width="40%" :visible="updateVisible" :confirm-loading="modalLoading" :footer="null" @change="XQHandleCancel">
      <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
        <a-form-model-item label="舜天发货单号">
          <a-input v-model="saintyCode" allowClear></a-input>
        </a-form-model-item>
      </a-form-model>
      <div class="footer-bts">
        <a-button type="default" @click="XQHandleCancel">取消</a-button>
        <a-button type="primary" @click="onSubmitXQ()">确定</a-button>
      </div>
    </a-modal>
  </a-card>
  </a-spin>
</template>

<script>
import OrderInfoCheckModal from '@/views/order_info/components/OrderInfoCheckModal.vue'
import {columns} from './components/colums.js'
import OrderShipInfoEditModal from './components/OrderShipInfoEditModal.vue'
import {delOrderShipInfo, listOrderShipInfo, selectByIdOrderShipInfo} from './api/OrderShipInfoApi'
import ImproveLogistics from './components/ImproveLogistics.vue'
import CheckLogistics from './components/CheckLogistics.vue'
import InvoiceReview from '@/views/order_ship_info/components/InvoiceReview.vue'
import {checkPermission} from "@/utils/permissions";
import {baseURL} from '@/utils/request';

// const pageSource = {
//   current: 1,
//   pageSize: 10,
//   total: 0,
//   showSizeChanger: true,
//   showTotal: () => `共${this.page.total}条`
// }
export default {
  name: 'orderShipInfo',
  components: {
    OrderShipInfoEditModal,
    ImproveLogistics,
    CheckLogistics,
    OrderInfoCheckModal,
    InvoiceReview
  },
  data() {
    return {
      spinning:false,
      columns: columns,
      confirmLoading: false,
      visible: false,
      rejectReason: '',
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: {y: 600},
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      // page: Object.assign({}, pageSource),
      isCheck: checkPermission('order:ship:check'),
      isAudit: checkPermission('order:ship:audit'),
      isExport: checkPermission('order:ship:export'),
      isDeliver: checkPermission('order:ship:deliver'),
      isCheckLogistics: checkPermission('order:ship:check_logistics'),
      isEditLogistics: checkPermission('order:ship:edit_logistics'),
      isShuntianShipNum: checkPermission('order:ship:shuntian_shipnum'),
      isExportShipOrderDetail: checkPermission('order:ship:export_ship_detail'),
      isCancel: checkPermission('order:ship:cancle_ship_index'),
      selectedRowKeys: [],
      selectedRows: [],
      updateVisible: false,
      modalLoading: false,
      saintyCode:'',
      //上传文件地址
      uploadUrl: baseURL + '/api/order/system/orderShipInfo/excelReader',
      updateK3Url: baseURL + '/api/order/system/orderShipInfo/excelReaderUpdateK3',
      //上传文件请求头
      headers: {
        'Authorization-Admin': this.$store.getters.token,
      },
      cancelVisible:false,
      cancelLoading:false,
      cancelRemark:''
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        // type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
     searchDataObj(){
      let obj = {
        ...this.searchData,
        ids:this.selectedRowKeys
      }
      return obj

    }
  },
  methods: {
    cancelShipInfo(){

      this.cancelLoading = true
      const param = {
        ids: this.selectedRows.map((item) => item.id),
        cancelRemark: this.cancelRemark
      }
      this.axios.post('/api/order/system/orderShipInfo/toBatchCancel', param).then((res) => {
        if (res.code == 200) {
          this.cancelLoading = false
          this.$message.success(res.message)
          this.cancelVisible = false
          this.cancelRemark = ''
          this.selectedRowKeys = []
          this.getData()
        }
      }).catch((e)=>{
        console.log(e)
        this.cancelLoading = false
      })
    },
    startDownLoad(){
      this.spinning = true
    },
    downLoadDone (){
      this.spinning = false
    },
    //导入
    uploadChange (info) {
      this.tableLoading  = true
      setTimeout(()=>{
        if(info.file.response){
          let hint = info.file.response
          if(hint.code === 200) {
            this.tableLoading  =  false
            this.$message.success(hint.message)
            this.getData()
          }else if(hint.code === 500) {
            this.tableLoading = false
            this.$message.error(hint.message)
          }
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList)
          }
        }
      })

    },
    //打开上游单据
    checkOrderInfo(row) {
      this.$refs.OrderInfoCheckModal.isShow(row, 'fhd')
    },
    /**
     * 获取表格数据
     */
    getData(num) {
      this.tableLoading = true
      if(num != 1){
        this.selectedRowKeys = []
        this.selectedRows = []
      }
      listOrderShipInfo({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        shippingOrderStatus: 2,
        deliveryStatus: 0,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const {total, records} = res.body
            this.page.total = total
            let list = []
            for (let i = 0; i < records.length; i++) {
              if (!records[i].orderCode) {
                records[i].orderCode = i
              } else {
                let code = records[i].orderCode.split('，')
                for (let x = 0; x < code.length; x++) {
                  list.push(code[x])
                }
              }
              records[i].orderCode = list
              list = []
            }
            this.tableData = records
            this.selectedRowKeys = []
            this.selectedRows = []
            this.searchData.shippingOrderStatus = 2
            this.searchData.deliveryStatus = 0
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    /**
     * 筛选查询
     */
    // getDataFilter() {
    //   this.page = Object.assign({}, pageSource)
    //   this.getData()
    // },
    getDataFilter() {
      this.page.current = 1;
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page.current = 1;
      this.page.pageSize = 10
      this.$nextTick( () => {
        this.$refs.pcd.setPCD();
      })
      this.getData()
    },

    XQHandleCancel() {
      this.updateVisible = false
      this.saintyCode = ''
    },
    // 修改需求单号
    onSubmitXQ() {
      if (!this.saintyCode) {
        this.$notification.info({
          message: '请输入舜天发货单号',
        })
        return
      }
      this.modalLoading = true
      let obj = {
        id: this.selectedRows[0].id,
        saintyCode: this.saintyCode
      }
      this.axios
        .post(`/api/order/system/orderShipInfo/updateSaintyCode`, obj).then(res =>{
        this.updateVisible = false
        this.saintyCode = ''
        this.modalLoading = false
        this.$message.success(res.message)
        this.getData()
      }).catch(err => {
        this.modalLoading = false
        this.$message.success(err.message)
      })
    },
    /**
     * 更改分页
     */
    // changeTable(pagination) {
    //   this.page = pagination
    //   this.getData()
    // },
    changeTable(pagination) {
      this.page = pagination
      this.getData(1)
    },

    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toBatchCancel(){
      const _this = this
      _this.$confirm({
        title: '警告',
        content: `取消发货单之后，订单货款会退回至经销商账户中，是否继续？`,
        okText: '确定',
        okType: 'primary',
        cancelText: '取消',
        onOk() {
          _this.cancelVisible = true
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    },
    toHandler(name) {
      const _this = this
      if (_this.selectedRows.length > 1 || _this.selectedRows.length <= 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'ship':
          _this.$refs.OrderShipInfoEditModal.setRowData(_this.selectedRows[0], 'ship')
          break
        case 'improve_logistics':
          _this.$refs.ImproveLogistics.getData(_this.selectedRows[0], 'improve_logistics')
          break
        case 'view_logistics':
          _this.$refs.CheckLogistics.getData(_this.selectedRows[0], 'view_logistics')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delOrderShipInfo(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({message: res.message})
                _this.getData()
              } else {
                _this.$notification.error({message: res.message})
              }
            },
            onCancel() {
            },
          })
          break
        case 'check':
          _this.$refs.OrderShipInfoEditModal.setRowData(_this.selectedRows[0], 'check')
          break
        case 'audit':
          _this.$refs.InvoiceReview.show(_this.selectedRows[0], 'audit')
          break
        case 'editSaintyCode':
          _this.saintyCode = _this.selectedRows[0].saintyCode
          _this.updateVisible = true
          break

      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}

.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
</style>
